<template>
    <div class="results">
        <div class="item" v-for="item in list" :key="item.id">
            <div class="pic">
                <van-image class="money-image" fit="cover" :src="imgMoney" alt=""/>
                <div class="date-type">
                    <div class="date">
                        {{item.billMonth}}
                    </div>
                </div>
            </div>
            <div class="money">
                ￥{{item.amount}}
            </div>
        </div>
        <no-more v-show="list.length !== 0"/>
        <no-data v-show="list.length === 0"/>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            imgMoney: require('@/assets/img/money.png'),
        }
    },
    methods: {
        getList() {
            let billDateStart = new Date().getFullYear() + '-01-01'
            let billDateEnd = new Date().getFullYear() + '-12-31'
            this.$api.post('person/base/person/person-memberConsumer', {billDateStart, billDateEnd}).then(res=>{
                //console.log(res)
                this.list = res
            })
        }
    },
    mounted() {
        this.getList()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .results{
        padding: 20px 20px 0;
        .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            background-color: #fff;
            padding:10px 20px;
            border-radius: 9px;
            box-shadow: 0 0 9px 6px $boxShadow;
            .money-image{
                width: 50px;
                height: 50px;
            }
            .pic{
                display: flex;
                align-items: center;
            }
            .date-type{
                .type{
                    font-size: 14px;
                }
                .date{
                    color: $gray;
                }
            }
            .money{
                font-size: 14px;
                font-weight: bold;
                color: $main;
            }
        }
    }

</style>